@media screen and (min-width:950px){
    .label-size{
        font-size: 20px;
    }
    .label-wrap{
        max-width: 90px;
    }
}
@media screen and (max-width:950px){
    .label-size{
        font-size: 18px;
    }
    .label-wrap{
        max-width: 90px;
    }
}
@media screen and (max-width:650px){
    .label-size{
        font-size: 18px;
    }
    .label-wrap{
        max-width: 90px;
    }
}
@media screen and (max-width:480px){
    .label-size{
        font-size: 16px;
    }
    .label-wrap{
        max-width: 90px;

    }
}