
.clampMe {
    height:'auto';
    white-space: 'nowrap';
    width: '100%'; 
    overflow: 'hidden'; 
    text-overflow: 'ellipsis';
}
@supports (-webkit-line-clamp: 4) {
    .clampMe {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
    }
}
@keyframes fadeOutAnimation {
    0%   { opacity:0; }
    50%  { opacity:0.5; }
    100% { opacity:1; }
}
@-o-keyframes fadeOutAnimation{
    0%   { opacity:0; }
    50%  { opacity:0.5; }
    100% { opacity:1; }
}
@-moz-keyframes fadeOutAnimation{
    0%   { opacity:0; }
    50%  { opacity:0.5; }
    100% { opacity:1; }
}
@-webkit-keyframes fadeOutAnimation{
    0%   { opacity:0; }
    50%  { opacity:0.5; }
    100% { opacity:1; }
}
/* .animate-vlogList {
    -webkit-animation: fadeOutAnimation 1.5s linear;
    -moz-animation: fadeOutAnimation 1.5s linear;
    -o-animation: fadeOutAnimation 1.5s linear;
    animation: fadeOutAnimation 1.5s linear;
} */