
.clampTitleText {
    height:'auto';
    white-space: 'nowrap';
    width: '100%'; 
    overflow: 'hidden'; 
    text-overflow: 'ellipsis';
}
@supports (-webkit-line-clamp: 2) {
    .clampTitleText {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}