.app-container {
    position: relative;
}
.content-container{
    position: relative;
    background: linear-gradient(90deg, #ffb74d,#f57c00);
    max-height: 100vh !important;
    display: flex;
    width: 100%;
}
.content-container:before{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%; 
    height: 31.5%;
    background:url('../assets/wave.png');
    background-size: cover;
    background-repeat: no-repeat;
}