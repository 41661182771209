html{
  width: 100%;
  height: 100%;
  background-color: #fff3e0;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  font-family: 'Roboto', sans-serif;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

footer{
  font-size: 12px; 
  text-align: center;
  font-style:italic;
  background-color: #333;
  width: 100%;
  bottom: 0;
  position: fixed;
  color: white;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}